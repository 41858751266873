<div class="login-content">
  <img class="close-icon" src="./../../../../assets/icons/x.svg" alt="" (click)="onNoClick()">

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="">
  </div>
  <p class="title">Are you sure?</p>

  <div class="buttons">
    <button class="primary-btn" type="submit" [mat-dialog-close]=true>Yes</button>
    <button class="primary-btn" type="submit" (click)="onNoClick()">No</button>
  </div>
</div>
