import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ILogin, IShortUser, IUser, IUserFullInfo, IUserRole} from '../interfaces/auth-interfaces';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Mapper} from './mapper';
import {Observable, of} from 'rxjs';
import { IInvoice, IProvider } from '../interfaces/provider';
import {IAlert} from '../interfaces/alert-interface';
import {ICity, ICountry, IIndustry, IProject, IState} from '../interfaces/project';
import { PaginationResponse } from '../interfaces/responses';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private httpClient: HttpClient) {
  }

  public createProviderAccount(data: { providerIdForUser: string }) {
    return this.httpClient.post(`${environment.apiUrl}admin/createUserLikeProvider`, data);
  }

  public getDoc(projectId: string) {
      return this.httpClient.get<Blob>(
          `${environment.apiUrl}pdf/getPdfDoc/${projectId}`,
          {responseType: 'blob' as 'json'}
      );
  }

  public getRequirementsDocFile(projectId: string) {
    return this.httpClient.get<Blob>(
      `${environment.apiUrl}pdf/getPdfDocWithoutBrief/${projectId}`,
      {responseType: 'blob' as 'json'}
    );
  }

  public getProvidersCountry(): Observable<any[]> {
    return this.httpClient.get<string[]>(`${environment.apiUrl}provider/getProvidersCountry`);
  }

  public providerTypes() {
    return this.httpClient.get(`${environment.apiUrl}provider-type/getAll`);
  }

  public createProvider(provider: object) {
    return this.httpClient.post(`${environment.apiUrl}admin/create-provider`, provider)
  }

  public uploadProviderLogo(id: string, payload: FormData) {
    return this.httpClient.post(`${environment.apiUrl}provider/uploadProviderLogo/${id}`, payload, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public changeUserEmail(id: string, data: object) {
    return this.httpClient.post(`${environment.apiUrl}admin/change-user-email/${id}`, data);
  }

  public getTestimonials() {
    return this.httpClient.get(`${environment.apiUrl}testimonial/getAll`);
  }

  public earningsPerAllTime() {
    return this.httpClient.get(`${environment.apiUrl}admin/earningsPerAllTime`);
  }

  public earningsPerYear() {
    return this.httpClient.get(`${environment.apiUrl}admin/earningsPerYear`);
  }

  public earningsPerMonth() {
    return this.httpClient.get(`${environment.apiUrl}admin/earningsPerMonth`);
  }

  public getProjectBriefs() {
    return this.httpClient.get(`${environment.apiUrl}admin/projectBriefs`);
  }

  public getPreps() {
    return this.httpClient.get(`${environment.apiUrl}prep/getAll`);
  }

  public getSubscriptions() {
    return this.httpClient.get(`${environment.apiUrl}admin/getProviderBySubscriptionPlan`);
  }

  public getServiceProviders() {
    return this.httpClient.get(`${environment.apiUrl}admin/serviceProviders`);
  }

  public createBrief(credential) {
    return this.httpClient.post(`${environment.apiUrl}project/create`, credential);
  }

  public createFromDto(credential) {
    return this.httpClient.post(`${environment.apiUrl}provider/createFromDto`, credential);
  }

  public upgradePlan(credential) {
    return this.httpClient.post(`${environment.apiUrl}admin/upgradePlan/${credential}`, '');
  }

  public sendBrief(credential) {
    return this.httpClient.post(`${environment.apiUrl}project/sendBrief`, credential);
  }

  public filteredUsers(credential,  skip = 0, limit = 10) {
    return this.httpClient.post(`${environment.apiUrl}admin/filteredUsers?skip=${skip}&limit=${limit}`, credential);
  }

  public filteredEarnings(credential) {
    return this.httpClient.post(`${environment.apiUrl}admin/platformEarning`, credential);
  }

  public filteredProviders(credential, query = 'active', skip = 0, limit = 10) {
    return this.httpClient.post(`${environment.apiUrl}admin/filteredProviders?type=${query}&skip=${skip}&limit=${limit}`, credential);
  }

  public filteredCountry(credential) {
    return this.httpClient.post(`${environment.apiUrl}admin/searchCountry`, credential);
  }

  public filteredProjects(credential, skip = 0, limit = 10) {
    return this.httpClient.post(`${environment.apiUrl}admin/filteredProjects?skip=${skip}&limit=${limit}`, credential);
  }

  public filteredDocs(credential, skip = 0, limit = 10) {
    return this.httpClient.post<PaginationResponse<IProject>>(`${environment.apiUrl}admin/docWithoutBrief?skip=${skip}&limit=${limit}`, credential);
  }

  public getAllPendingProjectBriefs(credential, skip = 0, limit = 10) {
    return this.httpClient.post<PaginationResponse<IProject>>(
        `${environment.apiUrl}admin/pendingProjects?skip=${skip}&limit=${limit}`, credential
    )
  }

  public filteredSubscriptionProvider(credential, skip = 0, limit = 10) {
    return this.httpClient.post(`${environment.apiUrl}admin/filteredSubscriptionProvider?skip=${skip}&limit=${limit}`, credential);
  }

  public sendBriefToProvider(providerId, projectId) {
    return this.httpClient.get(`${environment.apiUrl}project/sendAllDataBrief/${providerId}/${projectId}`);
  }

  public checkEmail(credential) {
    return this.httpClient.post(`${environment.apiUrl}user/checkEmail`, credential);
  }

  public editBrief(credential) {
    return this.httpClient.patch(`${environment.apiUrl}project/edit`, credential);
  }

  public createProjectType(credential) {
    return this.httpClient.post(`${environment.apiUrl}project-type/create`, credential);
  }

  public editProjectType(credential) {
    return this.httpClient.patch(`${environment.apiUrl}project-type/edit`, credential);
  }

  public editTestimonial(credential) {
    return this.httpClient.patch(`${environment.apiUrl}testimonial/edit`, credential);
  }

  public editProvider(credential) {
    return this.httpClient.patch(`${environment.apiUrl}provider/edit`, credential);
  }

  public providerToPrioritized(credential) {
    return this.httpClient.patch(`${environment.apiUrl}provider/to-prioritized`, credential);
  }

  public editProviderSub(credential) {
    return this.httpClient.post(`${environment.apiUrl}admin/editProviderSubscription`, credential);
  }

  public editLocation(credential, id) {
    return this.httpClient.patch(`${environment.apiUrl}headquarter/edit/${id}`, credential);
  }

  public editLocations(credential, id) {
    return this.httpClient.patch(`${environment.apiUrl}headquarter/editMany/${id}`, credential);
  }

  public editFullUser(credential) {
    return this.httpClient.patch(`${environment.apiUrl}admin/editFullUser`, credential);
  }


  public editProjectDetail(credential) {
    return this.httpClient.patch(`${environment.apiUrl}projectDetails/edit`, credential);
  }

  public createMethod(credential) {
    return this.httpClient.post(`${environment.apiUrl}acceptCompensation/create`, credential);
  }

  public editMethod(credential) {
    return this.httpClient.patch(`${environment.apiUrl}acceptCompensation/edit`, credential);
  }

  public createPrep(credential) {
    return this.httpClient.post(`${environment.apiUrl}prep/create`, credential);
  }

  public createTestimonial(credential) {
    return this.httpClient.post(`${environment.apiUrl}testimonial/create`, credential);
  }

  public editPrep(credential) {
    return this.httpClient.patch(`${environment.apiUrl}prep/edit`, credential);
  }

  public closeProject(credential) {
    return this.httpClient.patch(`${environment.apiUrl}project/changeStatusToClosed/${credential}`, {});
  }

  public getAlertsSent(): Observable<IAlert[]> {
    return this.httpClient.get(`${environment.apiUrl}alert/getAllByStatus/${2}`).pipe(
      map((alerts: any[]) => Mapper.mapAlertsToIAlerts(alerts))
    );
  }

  public getAlertsOpen(): Observable<IAlert[]> {
    return this.httpClient.get(`${environment.apiUrl}alert/getAllByStatus/${0}`).pipe(
      map((alerts: any[]) => Mapper.mapAlertsToIAlerts(alerts))
    );
  }

  public getAlertsInterest(): Observable<IAlert[]> {
    return this.httpClient.get(`${environment.apiUrl}alert/getAllByStatus/${1}`).pipe(
      map((alerts: any[]) => Mapper.mapAlertsToIAlerts(alerts))
    );
  }

  public getSpec() {
    return this.httpClient.get<string[]>(`${environment.apiUrl}provider/getSpecializationAreasAndIndustrySpecializations`);
  }

  public getSkills() {
    return this.httpClient.get<string[]>(`${environment.apiUrl}skill/getAll`);
  }

  public getAccept() {
    return this.httpClient.get(`${environment.apiUrl}acceptCompensation/getAllForAdmin`);
  }

  public getPrep() {
    return this.httpClient.get(`${environment.apiUrl}prep/getAllForProject`);
  }

  public getCountries(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(`${environment.apiUrl}country/`);
  }

  public getStates(countryId: string): Observable<IState[]> {
    return this.httpClient.get<IState[]>(`${environment.apiUrl}country/states/${countryId}`);
  }

  public getCities(stateId: string): Observable<ICity[]> {
    return this.httpClient.get<ICity[]>(`${environment.apiUrl}country/cities/${stateId}`);
  }

  public getIndustries(): Observable<IIndustry[]> {
    return this.httpClient.get<any[]>(`${environment.apiUrl}industry/getAll`)
      .pipe(
        catchError(() => of([])),
        map((industry: any[]) => Mapper.mapIndustriesToIIndustries(industry)));
  }

  public getDetails() {
    return this.httpClient.get(`${environment.apiUrl}projectDetails/getAllActive`);
  }

  public getDetailsAdmin() {
    return this.httpClient.get(`${environment.apiUrl}projectDetails/getAll`);
  }

  public getProjectTypes() {
    return this.httpClient.get(`${environment.apiUrl}project-type/getAllProjectType`);
  }

  public getAllProjectTypeForAdmin() {
    return this.httpClient.get(`${environment.apiUrl}project-type/getAllProjectTypeForAdmin`);
  }

  public getBriefs(item): Observable<IProject[]> {
    return this.httpClient.get(`${environment.apiUrl}project/getAllMyProject/${item}/`).pipe(
      map((alerts: any[]) => Mapper.mapBriefsToIBriefs(alerts))
    );
  }

  public login(credential: ILogin) {
    return this.httpClient.post(`${environment.apiUrl}admin/signIn/`, credential).pipe(
      tap((token: any) => {
        localStorage.setItem('token', token.token);
      })
    );
  }

  public changePassword(credential) {
    return this.httpClient.post(`${environment.apiUrl}user/resetPassword`, credential).pipe(
      tap((token: any) => {
        localStorage.setItem('token', token.tokenId);
      })
    );
  }

  public confirmEmail(token) {
    return this.httpClient.post(`${environment.apiUrl}user/confirmEmail/${token}`, {});
  }

  public doSearch(credential) {
    return this.httpClient.post(`${environment.apiUrl}provider/getFilteredProviders`, credential).pipe(
      map((providers: any[]) => Mapper.mapProvidersToIProviders(providers))
    );
  }

  public createCustomer(credential) {
    return this.httpClient.post(`${environment.apiUrl}customer/v1/create`, credential);
  }

  public projectDetailsCreate(credential) {
    return this.httpClient.post(`${environment.apiUrl}projectDetails/create`, credential);
  }

  public createCustomerUserToken(credential) {
    return this.httpClient.post(`${environment.apiUrl}customer/create/userToken`, credential);
  }

  public chargeCustomerFirstPrice() {
    return this.httpClient.post(`${environment.apiUrl}customer/chargeCustomerFirstPrice`, {});
  }

  public chargeCustomerSecondPrice() {
    return this.httpClient.post(`${environment.apiUrl}customer/chargeCustomerSecondPrice`, {});
  }

  public chargeCustomerThirdPrice() {
    return this.httpClient.post(`${environment.apiUrl}customer/chargeCustomerThirdPrice`, {});
  }


  public createCustomerProvider(name, email) {
    return this.httpClient.post(`${environment.apiUrl}customer/v1/createProvider/${encodeURIComponent(email)}`, {name: name});
  }

  public createCustomerProviderToken(email, credential) {
    return this.httpClient.post(`${environment.apiUrl}customer/create/providerToken/${encodeURIComponent(email)}`, credential);
  }

  public chargeProviderAddCard(email) {
    return this.httpClient.post(`${environment.apiUrl}customer/add/providerCard/${encodeURIComponent(email)}`, '');
  }

  public chargeProviderPrice(email, numberPrice) {
    return this.httpClient.post(`${environment.apiUrl}customer/chargeProviderPrice/${encodeURIComponent(email)}/${numberPrice}`, {});
  }

  public resetPassword(credential: string) {
    return this.httpClient.post(`${environment.apiUrl}user/forgotPassword/${credential}`, {});
  }


  public sendVerifyData(credential) {
    return this.httpClient.post(`${environment.apiUrl}user/verify-phone/`, credential).pipe(
      tap((token: any) => {
        localStorage.setItem('token', token.token);
      })
    );
  }

  public sendVerifyPhoneNumber(phoneNumber) {
    return this.httpClient.post(`${environment.apiUrl}user/sendVerifyCode/`, {phone: phoneNumber});
  }

  public signUpFirstStep(credential: IUser) {
    return this.httpClient.post(`${environment.apiUrl}user/singUp/`, credential).pipe(
      tap((res: any) => localStorage.setItem('token', res.token)),
      map((user: any) => Mapper.mapUserToIUser(user.user))
    );
  }

  public signUpSecondStep(credential: IUserRole, link: string) {
    return this.httpClient.post(`${environment.apiUrl}${link}/create/`, credential).pipe(
      map((user: any) => Mapper.mapUserRoleToIUserRole(user))
    );
  }

  public getUserById() {
    return this.httpClient.get(`${environment.apiUrl}user/getProfile/`)
      .pipe(
        map((user: any) => Mapper.mapUserInfoToIUserInfo(user))
      );
  }

  public getCurrentUser() {
    return this.httpClient.get<IShortUser>(`${environment.apiUrl}admin/current-admin/`);
  }
  public getUserFullInfo(id) {
    return this.httpClient.get(`${environment.apiUrl}admin/getFullUserInfo/${id}`);
  }

  public getTypeFullInfo(id) {
    return this.httpClient.get(`${environment.apiUrl}project-type/getById/${id}`);
  }

  public getTestimonialById(id) {
    return this.httpClient.get(`${environment.apiUrl}testimonial/getById/${id}`);
  }

  public getAcceptById(id) {
    return this.httpClient.get(`${environment.apiUrl}acceptCompensation/getById/${id}`);
  }

  public getCountryById(id) {
    return this.httpClient.get(`${environment.apiUrl}country/getById/${id}`);
  }

  public getPrepFullInfo(id) {
    return this.httpClient.get(`${environment.apiUrl}prep/getById/${id}`);
  }

  public getProviderFullInfo(id) {
    return this.httpClient.get(`${environment.apiUrl}admin/getFullProviderInfo/${id}`);
  }

  public getFullProjectInfo(id) {
    return this.httpClient.get(`${environment.apiUrl}admin/getFullProjectInfo/${id}`);
  }

  public getRequirementsDocWithoutBrief(id) {
    return this.httpClient.get(`${environment.apiUrl}admin/getDocWithoutBriefFullINfo/${id}`);
  }

  public deleteProjectDetail(id) {
    return this.httpClient.delete(`${environment.apiUrl}projectDetails/deleteOne/${id}`);
  }

  public deleteLocation(otherLocationId, id) {
    return this.httpClient.delete(`${environment.apiUrl}headquarter/deleteById/${otherLocationId}/${id}`);
  }

  public deleteUser(id) {
    return this.httpClient.delete(`${environment.apiUrl}admin/deleteUser/${id}`);
  }

  public providerDelete(id) {
    return this.httpClient.delete(`${environment.apiUrl}provider/deleteOne/${id}`);
  }

  public getProviderById(id) {
    return this.httpClient.get(`${environment.apiUrl}provider/getById/${id}`)
      .pipe(
        map((user: any) => Mapper.mapProviderToIProvider(user))
      );
  }

  public getBriefById(id) {
    return this.httpClient.get(`${environment.apiUrl}project/getById/${id}`);
  }


  public getAllProviders(): Observable<IProvider[]> {
    return this.httpClient.get(`${environment.apiUrl}provider/getAll/`).pipe(
      map((providers: any[]) => Mapper.mapProvidersToIProviders(providers))
    );
  }

  public getAllNewlyAddedAndFeatured() {
    return this.httpClient.get(`${environment.apiUrl}provider/getAllNewlyAddedAndFeatured`);
  }

  public editAccount(user, role): Observable<IShortUser> {
    return this.httpClient.patch<IShortUser>(`${environment.apiUrl}${role}/edit/`, user);
  }

  public getProviderIndustries(): Observable<IIndustry[]> {
    return this.httpClient.get<IIndustry[]>(`${environment.apiUrl}industry/getAllIndustriesForProviderUser`);
  }

  public getCustomerInvoices(providerId: string) {
    return this.httpClient.get<IInvoice[]>(`${environment.apiUrl}customer/invoices/${providerId}`);
  }
}
