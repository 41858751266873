import {IUser, IUserFullInfo, IUserRole} from '../interfaces/auth-interfaces';
import {IProvider} from '../interfaces/provider';
import {IAlert} from '../interfaces/alert-interface';
import {IIndustry, IProject} from '../interfaces/project';

export class Mapper {

  public static mapUserToIUser(data: any): IUser {
    return {
      confirmed: data.confirmed,
      isPhoneVerified: data.isPhoneVerified,
      _id: data._id,
      passwordHash: data.passwordHash,
      name: data.name,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      subscriptionType: data.subscriptionType,
      role: data.role,
      country: data.country
    };
  }

  public static mapUserRoleToIUserRole(data: any): IUserRole {
    return {
      companyName: data.companyName,
      industry: data.industry,
      companySize: data.companySize,
      country: data.country,
      city: data.city,
      state: data.state,
      companyWebSite: data.companyWebSite,
      companyPhoneNumber: data.phoneNumber,
      postalCode: data.postalCode,
      _id: data.id,
      userId: data.userId,
      logoUrl: data.logoUrl,
      headOfficeAddress: data.headOfficeAddress,
      primaryContact: data.primaryContact,
      fullName: data.fullName,
      homeCity: data.homeCity,
      education: data.education,
      occupation: data.occupation,
      linkedinLink: data.linkedinLink,
      day: data.day,
      month: data.month,
      year: data.year,
      gender: data.gender,
      description: data.description
    };
  }

  public static mapIndustryToIndustry(industry: any): IIndustry {
    if (industry) {
      return {
        _id: industry._id,
        name: industry.title,
        description: industry.description,
      } as IIndustry;
    }
  }

  public static mapIndustriesToIIndustries(industries: any[]): IIndustry[] {
    return industries.map((country: any) => this.mapIndustryToIndustry(country));
  }

  public static mapUserInfoToIUserInfo(data: any): IUserFullInfo {
    return {
      user: this.mapUserToIUser(data.user),
      userRole: this.mapUserRoleToIUserRole(data.userRole),
    };
  }

  public static mapProviderToIProvider(provider: any): IProvider {
    return {
      companyName: provider.companyName,
      companyWebSite: provider.companyWebSite,
      city: provider.city,
      country: provider.country,
      clutchScore: provider.clutchScore,
      about: provider.about,
      phoneNumber: provider.phoneNumber,
      industry: provider.industry,
      companySize: provider.companySize,
      hourlyRate: provider.hourlyRate,
      minProjectAmount: provider.minProjectAmount,
      specializationAreas: provider.specializationAreas,
      industrySpecialization: provider.industrySpecialization,
      headquarterLocation: provider.headquarterLocation,
      otherLocations: provider.otherLocations,
      logo: provider.logo,
      background: provider.background,
      _id: provider._id
    } as IProvider;
  }

  public static mapProvidersToIProviders(providers: any[]): IProvider[] {
    return providers.map((provider: any) => this.mapProviderToIProvider(provider));
  }

  public static mapAlertToIAlert(alert: any): IAlert {
    return {
      _id: alert._id,
      name: alert.name,
      logo: alert.logo,
      status: alert.status,
      projectName: alert.projectName,
      user: alert.user,
      category: alert.category,
      event: alert.event,
      sg_message_id: alert.sg_message_id,
      timestamp: alert.timestamp,
      providerName: alert.providerName
    } as IAlert;
  }

  public static mapAlertsToIAlerts(alerts: any[]): IAlert[] {
    return alerts.map((alerts: any) => this.mapAlertToIAlert(alerts));
  }

  public static mapBriefToIBrief(brief: any): IProject {
    return {
      _id: brief._id,
      briefId: brief.briefId,
      name: brief.name,
      types: brief.types,
      country: brief.country,
      specialization: brief.specialization,
      skillAreas: brief.skillAreas,
      acceptCompensation: brief.acceptCompensation,
      projectDetails: brief.projectDetails,
      prep: brief.prep,
      otherThings: brief.otherThings,
      elseSomeAssist: brief.elseSomeAssist,
      proposalValidUntil: brief.proposalValidUntil,
      budget: brief.budget,
      specialMessage: brief.specialMessage,
      examples: brief.examples,
      status: brief.status,
      userId: brief.userId,
      lastEdit: brief.lastEdit,
      completionPercentage: brief.completionPercentage,
      provider: brief.provider,
      projectBriefDistributedTo: brief.projectBriefDistributedTo,
      providersWhoViewedYourProposal: brief.providersWhoViewedYourProposal,
      providersInterested: brief.providersInterested,
      done: brief.done,
      field: brief.field,
    } as IProject;
  }

  public static mapBriefsToIBriefs(alerts: any[]): IProject[] {
    return alerts.map((briefs: any) => this.mapBriefToIBrief(briefs));
  }
}
