import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent, CardNumberPipe} from './app.component';
import {HomeModule} from './modules/home/home.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {CustomInterceptor} from './services/interceptor.service';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {DeleteBlockQuestionComponent} from './modules/popups/delete-block-question/delete-block-question.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {CommonModule} from '@angular/common';
import { IsFeaturedComponent } from './modules/popups/is-featured/is-featured.component';


@NgModule({
  declarations: [
    AppComponent,
    CardNumberPipe,
    DeleteBlockQuestionComponent,
    IsFeaturedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    HomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatSnackBarModule,
    CommonModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    MatDialogModule,
    MatTabsModule,
    NgxUiLoaderModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }],
  exports: [CardNumberPipe, CommonModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
