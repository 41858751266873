import {Component, EventEmitter, Output} from '@angular/core';
import {AuthDataService} from '../../services/auth-data.service';
import {ILogin, IShortUser, IUserToken} from '../../interfaces/auth-interfaces';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  public loginForm: FormGroup = this.fb.group({
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    password: ['', [
      Validators.required,
      Validators.minLength(6)
    ]],
  });

  constructor(
    private fb: FormBuilder,
    private authDS: AuthDataService,
    private router: Router,
    public snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService) {
  }

  public doLogin() {
    this.ngxService.start();
    this.authDS.doLogin(this.loginForm.value).subscribe({
        next: (resData: IUserToken) => {
          localStorage.setItem('token', resData.token);
          this.authDS._token$.next(resData.token);
          this.authDS.setUser(resData.admin as IShortUser);
          this.router.navigate(['/admin']).then();
          this.ngxService.stop();
        }, error: (error) => {
          this.snackbar.open(error.error.message, '', {
            duration: 2500,
          });
          this.ngxService.stop();
        }
      }
    );
  }

}
