import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-block-question',
  templateUrl: './delete-block-question.component.html',
  styleUrls: ['./delete-block-question.component.scss']
})
export class DeleteBlockQuestionComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteBlockQuestionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
