import {Component, Directive, HostBinding, HostListener, inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {AuthDataService} from './services/auth-data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private authDS = inject(AuthDataService);
  private ngxService = inject(NgxUiLoaderService);

  title = 'unicorn-web';

  ngOnInit() {
    const token = localStorage.getItem('token');
    if(!token) return this.authDS.forceLogout();
    this.authDS._token$.next(token);
    this.authDS.getCurrentUser().subscribe({
      next: (res) => {
        this.authDS.setUser(res);
        localStorage.setItem('token', token);
        this.ngxService.stop();
      },
      error: error => {
        this.authDS.forceLogout();
        this.ngxService.stop();
      }
    });
  }
}

@Pipe({
  name: 'cardNumber'
})
export class CardNumberPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
    }
  }
}

@Directive({
  selector: '[credit-card]'
})
export class CreditCardDirective {

  @HostBinding('style.border')
  border: string;

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 16);
    }

    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }

    input.value = numbers.join(' ');

    this.border = '';
    if (/[^\d]+/.test(trimmed)) {
      this.border = '1px solid red';
    }

  }
}
