import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {AuthDataService} from '../services/auth-data.service';
import {catchError, map} from 'rxjs/operators';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthDataService);
  const token = localStorage.getItem('token');
  if(!token) return router.navigate(['/']);
  authService._token$.next(token);
  return authService.getCurrentUser().pipe(
    map((res) => {
      authService.setUser(res);
      localStorage.setItem('token', token);
      return true;
    }),
    catchError(err => {
      authService.forceLogout();
      return router.navigate(['/']);
    }),
  )
};
