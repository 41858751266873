import {Injectable} from '@angular/core';
import {AuthApiService} from './auth-api.service';
import {ILogin, IShortUser, IUser, IUserFullInfo} from '../interfaces/auth-interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {IProvider} from '../interfaces/provider';
import {tap} from 'rxjs/operators';
import {IAlert} from '../interfaces/alert-interface';
import { ICity, ICountry, IIndustry, IProject, IState } from '../interfaces/project';
import { PaginationResponse } from '../interfaces/responses';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  constructor(private authAS: AuthApiService, private router: Router) {
  }

  public _token$: BehaviorSubject<string> = new BehaviorSubject('');
  public token$: Observable<string> = this._token$.asObservable();
  public _user$: BehaviorSubject<IShortUser> = new BehaviorSubject(null);
  public user$: Observable<IShortUser> = this._user$.asObservable();
  public _providers$: BehaviorSubject<IProvider[]> = new BehaviorSubject(null);
  public providers$: Observable<IProvider[]> = this._providers$.asObservable();
  public _alerts$: BehaviorSubject<IAlert[]> = new BehaviorSubject(null);
  public alerts$: Observable<IAlert[]> = this._alerts$.asObservable();
  public _briefs$: BehaviorSubject<IProject[]> = new BehaviorSubject(null);
  public briefs$: Observable<IProject[]> = this._briefs$.asObservable();
  public _filters$: BehaviorSubject<any> = new BehaviorSubject(null);
  public filters$: Observable<any> = this._filters$.asObservable();
  public _filtersResults$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public filtersResults$: Observable<boolean> = this._filtersResults$.asObservable();
  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public getUser() {
    return this._user$.getValue();
  }

  public createProviderAccount(data: { providerIdForUser: string }) {
    return this.authAS.createProviderAccount(data);
  }

  public getDoc(projectId: string) {
    return this.authAS.getDoc(projectId);
  }

  public getRequirementsDocFile(projectId: string) {
    return this.authAS.getRequirementsDocFile(projectId);
  }

  public getProvidersCountry(): Observable<string[]> {
    return this.authAS.getProvidersCountry();
  }

  getData(): Observable<any> {
    return this._data.asObservable();
  }

  setData(data: any) {
    this._data.next(data);
  }

  public createFromDto(credential) {
    return this.authAS.createFromDto(credential);
  }

  public providerTypes() {
    return this.authAS.providerTypes();
  }

  public changeUserEmail(id: string, data: object) {
    return this.authAS.changeUserEmail(id, data);
  }

  public createProvider(view: object) {
    return this.authAS.createProvider(view);
  }

  public uploadProviderLogo(id: string, payload: FormData) {
    return this.authAS.uploadProviderLogo(id, payload);
  }

  public getFullProjectInfo(id) {
    return this.authAS.getFullProjectInfo(id);
  }

  public getRequirementsDocWithoutBrief(id) {
    return this.authAS.getRequirementsDocWithoutBrief(id);
  }

  public createProjectType(credential) {
    return this.authAS.createProjectType(credential);
  }

  public editProjectType(credential) {
    return this.authAS.editProjectType(credential);
  }

  public editTestimonial(credential) {
    return this.authAS.editTestimonial(credential);
  }

  public upgradePlan(credential) {
    return this.authAS.upgradePlan(credential);
  }

  public editProjectDetail(credential) {
    return this.authAS.editProjectDetail(credential);
  }

  public editProvider(credential) {
    return this.authAS.editProvider(credential);
  }

  public providerToPrioritized(credential) {
    return this.authAS.providerToPrioritized(credential);
  }

  public editProviderSub(credential) {
    return this.authAS.editProviderSub(credential);
  }

  public editLocation(credential, id) {
    return this.authAS.editLocation(credential, id);
  }

  public editLocations(credential, id) {
    return this.authAS.editLocations(credential, id);
  }

  public editFullUser(credential) {
    return this.authAS.editFullUser(credential);
  }

  public createPrep(credential) {
    return this.authAS.createPrep(credential);
  }

  public editPrep(credential) {
    return this.authAS.editPrep(credential);
  }

  public createMethod(credential) {
    return this.authAS.createMethod(credential);
  }

  public editMethod(credential) {
    return this.authAS.editMethod(credential);
  }

  public getUserFullInfo(id) {
    return this.authAS.getUserFullInfo(id);
  }

  public getTypeFullInfo(id) {
    return this.authAS.getTypeFullInfo(id);
  }

  public getTestimonialById(id) {
    return this.authAS.getTestimonialById(id);
  }

  public getPrepFullInfo(id) {
    return this.authAS.getPrepFullInfo(id);
  }

  public deleteProjectDetail(id) {
    return this.authAS.deleteProjectDetail(id);
  }

  public deleteUser(id) {
    return this.authAS.deleteUser(id);
  }

  public deleteLocation(otherLocationId, id) {
    return this.authAS.deleteLocation(otherLocationId, id);
  }

  public providerDelete(id) {
    return this.authAS.providerDelete(id);
  }

  public getPreps() {
    return this.authAS.getPreps();
  }

  public getTestimonials() {
    return this.authAS.getTestimonials();
  }

  public getProviderFullInfo(id) {
    return this.authAS.getProviderFullInfo(id);
  }

  public earningsPerAllTime() {
    return this.authAS.earningsPerAllTime();
  }

  public earningsPerYear() {
    return this.authAS.earningsPerYear();
  }

  public earningsPerMonth() {
    return this.authAS.earningsPerMonth();
  }

  public getProjectBriefs() {
    return this.authAS.getProjectBriefs();
  }

  public getSubscriptions() {
    return this.authAS.getSubscriptions();
  }

  public getServiceProviders() {
    return this.authAS.getServiceProviders();
  }

  public getSpec() {
    return this.authAS.getSpec();
  }

  public getSkills() {
    return this.authAS.getSkills();
  }

  public getCountries(): Observable<ICountry[]> {
    return this.authAS.getCountries();
  }

  public getStates(countryId: string): Observable<IState[]> {
    return this.authAS.getStates(countryId);
  }

  public getCities(stateID: string): Observable<ICity[]> {
    return this.authAS.getCities(stateID);
  }

  public getIndustries() {
    return this.authAS.getIndustries();
  }

  public getPrep() {
    return this.authAS.getPrep();
  }

  public getAccept() {
    return this.authAS.getAccept();
  }

  public getAcceptById(id) {
    return this.authAS.getAcceptById(id);
  }

  public getCountryById(id) {
    return this.authAS.getCountryById(id);
  }

  public getDetails() {
    return this.authAS.getDetails();
  }

  public getDetailsAdmin() {
    return this.authAS.getDetailsAdmin();
  }

  public getProjectTypes() {
    return this.authAS.getProjectTypes();
  }

  public getAllProjectTypeForAdmin() {
    return this.authAS.getAllProjectTypeForAdmin();
  }

  public createBrief(credential) {
    return this.authAS.createBrief(credential);
  }

  public sendBrief(credential) {
    return this.authAS.sendBrief(credential);
  }

  public filteredUsers(credential, skip?, limit?) {
    return this.authAS.filteredUsers(credential, skip, limit);
  }

  public filteredSubscriptionProvider(credential, skip?, limit?) {
    return this.authAS.filteredSubscriptionProvider(credential, skip, limit);
  }

  public filteredProjects(credential, skip?, limit?) {
    return this.authAS.filteredProjects(credential, skip, limit);
  }

  public filteredDocs(credential, skip?, limit?) {
    return this.authAS.filteredDocs(credential, skip, limit);
  }

  public getAllPendingProjectBriefs(credential, skip?, limit?): Observable<PaginationResponse<IProject>> {
    return this.authAS.getAllPendingProjectBriefs(credential, skip, limit)
  }

  public filteredEarnings(credential) {
    return this.authAS.filteredEarnings(credential);
  }


  public filteredProviders(credential, query, skip?, limit?) {
    return this.authAS.filteredProviders(credential, query, skip, limit);
  }

  public filteredCountry(credential) {
    return this.authAS.filteredCountry(credential);
  }

  public sendBriefToProvider(providerId, projectId) {
    return this.authAS.sendBriefToProvider(providerId, projectId);
  }

  public checkEmail(credential) {
    return this.authAS.checkEmail(credential);
  }

  public editBrief(credential) {
    return this.authAS.editBrief(credential);
  }

  public closeProject(credential) {
    return this.authAS.closeProject(credential);
  }

  public setProviders(pies: IProvider[]) {
    this._providers$.next(pies);
  }

  public setUser(user: IShortUser) {
    this._user$.next(user);
  }

  public setAlerts(pies: IAlert[]) {
    this._alerts$.next(pies);
  }

  public setBriefs(pies: IProject[]) {
    this._briefs$.next(pies);
  }

  public doLogin(credential: ILogin) {
    return this.authAS.login(credential);
  }

  public doSearch(credential) {
    return this.authAS.doSearch(credential);
  }

  public createCustomer(credential) {
    return this.authAS.createCustomer(credential);
  }

  public projectDetailsCreate(credential) {
    return this.authAS.projectDetailsCreate(credential);
  }


  public createCustomerProvider(name, email) {
    return this.authAS.createCustomerProvider(name, email);
  }

  public createCustomerProviderToken(email, credential) {
    return this.authAS.createCustomerProviderToken(email, credential);
  }

  public chargeProviderAddCard(email) {
    return this.authAS.chargeProviderAddCard(email);
  }

  public chargeProviderPrice(email, numberPrice) {
    return this.authAS.chargeProviderPrice(email, numberPrice);
  }

  public createCustomerUserToken(credential) {
    return this.authAS.createCustomerUserToken(credential);
  }

  public chargeCustomerFirstPrice() {
    return this.authAS.chargeCustomerFirstPrice();
  }

  public chargeCustomerSecondPrice() {
    return this.authAS.chargeCustomerSecondPrice();
  }

  public chargeCustomerThirdPrice() {
    return this.authAS.chargeCustomerThirdPrice();
  }

  public resetPassword(credential: string) {
    return this.authAS.resetPassword(credential);
  }

  public signUpFirstStep(credential: IUser) {
    return this.authAS.signUpFirstStep(credential);
  }

  public editAccount(credential, role) {
    return this.authAS.editAccount(credential, role).subscribe(
      (user) => this._user$.next(user)
    );
  }

  public confirmEmail(token) {
    return this.authAS.confirmEmail(token);
  }

  public sendVerifyPhoneNumber(credential) {
    return this.authAS.sendVerifyPhoneNumber(credential);
  }

  public sendVerifyData(credential) {
    return this.authAS.sendVerifyData(credential);
  }

  public signUpSecondStep(credential, link: string) {
    return this.authAS.signUpSecondStep(credential, link);
  }

  public forceLogout(): void {
    this.setUser(null);
    this._token$.next(null);
    localStorage.clear();
    this.router.navigate(['/']).then();
  }

  // public getUserById(): Observable<IUserFullInfo> {
  //   return this.authAS.getUserById();
  // }

  public getCurrentUser(): Observable<IShortUser> {
    return this.authAS.getCurrentUser();
  }

  public getProviderById(id): Observable<IProvider> {
    return this.authAS.getProviderById(id);
  }

  public getBriefById(id) {
    return this.authAS.getBriefById(id);
  }

  public getAllProviders(): Observable<IProvider[]> {
    return this.authAS.getAllProviders().pipe(tap((providers: IProvider[]) => this.setProviders(providers)));
  }

  public getAllNewlyAddedAndFeatured() {
    return this.authAS.getAllNewlyAddedAndFeatured();
  }

  public getAlertsSent(): Observable<IAlert[]> {
    return this.authAS.getAlertsSent().pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getAlertsOpen(): Observable<IAlert[]> {
    return this.authAS.getAlertsOpen().pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getAlertsInterest(): Observable<IAlert[]> {
    return this.authAS.getAlertsInterest().pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getBriefs(item): Observable<IProject[]> {
    return this.authAS.getBriefs(item).pipe(tap((alerts: IProject[]) => this.setBriefs(alerts)));
  }

  public createTestimonial(credential) {
    return this.authAS.createTestimonial(credential);
  }

  public getProviderIndustries(): Observable<IIndustry[]> {
    return this.authAS.getProviderIndustries();
  }

  public getCustomerInvoices(providerId: string) {
    return this.authAS.getCustomerInvoices(providerId);
  }
}
