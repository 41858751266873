import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {MatMenuModule} from '@angular/material/menu';
import {HomeRoutingModule} from './home-routing.module';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [HomeComponent],
  exports: [
    HomeComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSliderModule,
    FormsModule,
  ]
})
export class HomeModule {
}
