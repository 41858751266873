<section class="home-page">
  <div class="opacity-box"></div>
  <div class="container">
    <header>
      <div class="logo">
        <img src="../../../assets/logo/unicorn-logo.svg" alt="logo">
      </div>
    </header>
    <div class="banner">
      <div class="banner-content login-content">
        <form class="login-content-form" [formGroup]="loginForm">
          <label class="paragraph2" for="email"><b>Email</b> *</label>
          <input [ngClass]="{'error-input': loginForm.controls['email'].invalid && loginForm.controls['email'].touched}"
                 class="data-input" id="email" type="text" placeholder="Enter email" required
                 formControlName="email">

          <label class="paragraph2" for="password"><b>Password</b> *</label>
          <input [ngClass]="{'error-input': loginForm.controls['password'].invalid && loginForm.controls['password'].touched}"
                 class="data-input" id="password" type="password" placeholder="Enter password" required
                 formControlName="password">

        </form>

        <button class="primary-btn" type="submit" (click)="doLogin()">Log in</button>


      </div>
    </div>
  </div>
</section>
